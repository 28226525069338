import React from "react"

import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../../components/Hero"
import RibbonText from "../../components/ui/RibbonText"
import Card from "../../components/ui/Card"
import ContactBanner from "../../components/ContactBanner"
import Seo from "../../components/Seo"

export default function WhatWeDo() {
  return (
    <Layout>
      <Seo title="What We Do" />
      <Hero
        content={
          <div className="h-full flex flex-col justify-between md:justify-start">
            <h1 className="mb-12 max-w-md !normal-case">What We Do</h1>
            <p className="w-5/6 text-center self-center sm:text-left sm:self-auto sm:w-48">
              Builder Finders are independent builder brokers that assist with
              everything builder related.
            </p>
          </div>
        }
        background={
          <StaticImage
            src="../../images/what-we-do.jpeg"
            className="w-full h-full"
            quality={100}
            alt="A modern house with a garage and cobblestone driveway."
          />
        }
      />
      <section className="wrapper py-4 sm:pb-8 md:py-12 lg:pb-16 lg:pt-20">
        <div className="container lg:pr-5vh">
          <h2>Choosing a builder is time consuming, confusing and costly.</h2>
          <h2>
            Here’s how we save you <span className="text-tertiary">time</span>{" "}
            and <span className="text-tertiary">money</span>.
          </h2>
          <div className="py-6 lg:py-20">
            <StaticImage
              src="../../images/roadmap.png"
              className="w-full h-full"
              objectFit="contain"
              alt="A roadmap of the home construction/renovation project. From meeting with Builder Finders broker to designer selection to design review to builder introduction to construction quotes review to builder selection to construction to handover."
            />
          </div>
          <div className="flex flex-col-reverse sm:flex-row sm:space-x-4 md:space-x-0 justify-between">
            <div className="sm:max-w-xs">
              <StaticImage
                src="../../images/shaking-hand.png"
                className="w-full h-full"
                alt="A man shaking a hand with a client."
              />
            </div>
            <div className="sm:w-1/2 flex flex-col items-end justify-between mb-4 sm:mb-0">
              <h3>
                Find the deal right for{" "}
                <span className="text-tertiary">you</span>
              </h3>
              <p className="italic text-black opacity-50 text-right w-80">
                Think of a finance broker arranging the best finance deal for
                your finance loan, except with Builder Finders we arrange the
                best possible deal for the construction of your new family home,
                investment property, renovation or extension.
              </p>
              <RibbonText
                text="Get started"
                highlighted="today"
                justify="right"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper py-8 md:py-16 bg-secondary-light">
        <div className="container flex flex-col">
          <h2 className="w-2/3 lg:w-1/3">
            We put confidence back into your{" "}
            <span className="text-tertiary">project</span>
          </h2>
          <div className="grid md:grid-cols-3 gap-6 md:gap-20 my-6 md:my-8">
            <Tile
              image={
                <StaticImage
                  src="../../images/interview.png"
                  alt="Two people having a discussion over coffee. One individual is holding a clipboard writing something down."
                />
              }
              title="Dealing with industry experts"
              content="We are industry experts giving you confidence in knowing that we thoroughly research all our builders to ensure you are working with a trusted builder.  Whether you want to remodel your existing home or build a new one from the ground up, Builder Finders together with our trusted home builders will help you achieve your goals."
            />
            <Tile
              image={
                <StaticImage
                  src="../../images/office-desk.png"
                  alt="An individual working in an office environment."
                />
              }
              title="Design"
              content="Builder Finders can find the design solutions that suits your exact requirements, whether it be a building designer or architect. If you’re a first home buyer or a seasoned investor, we can assist you to find the right designer for your project. As an industry partner of the BDAA (Building Designers Association of Australia) we have a large network of experienced designers to help you with all your building design requirements. Our professional advisors have years of construction industry experience and will take the time to listen and understand exactly what you want."
            />
            <Tile
              image={
                <StaticImage
                  src="../../images/two-builders.png"
                  alt="Two individuals wearing construction site safety equipment inspecting elements of a building."
                />
              }
              title="Independent inspections"
              content="Ensuring our highest construction standards Builder Finders completes an independent building inspection for each completed home. Every Builder Finders project includes a practical completion inspection by an independent registered builder upon completion of the home as part of our standard process."
            />
          </div>
          <RibbonText
            text="You're in safe"
            highlighted="hands"
            justify="right"
            className="flex flex-col items-end"
          />
        </div>
      </section>
      <ContactBanner />
    </Layout>
  )
}

function Tile({ image, title, content }) {
  return (
    <Card>
      <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-6 md:flex md:flex-col space-y-3 sm:space-y-0 md:space-y-6 h-full">
        {image}
        <div className="space-y-3">
          <h3>{title}</h3>
          <p className="italic opacity-50">{content}</p>
        </div>
      </div>
    </Card>
  )
}
